import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import * as SvgIcons from "src/app/partials/content/SVGIcons";
import { SelectedRowContext } from "src/app/context/SelectedRowContext";

const getMenuIcon = (name) => {
  switch (name) {
    case "LayersIcon":
      return <SvgIcons.LayersIcon />;
    case "ConnectorIcon":
      return <SvgIcons.ConnectorIcon />;
    case "SettingsIcon":
      return <SvgIcons.SettingsIcon />;
    case "Box2Icon":
      return <SvgIcons.Box2Icon />;
    case "GoogleAdsIcon":
      return <SvgIcons.GoogleAdsIcon />;
    case "MetaIcon":
      return <SvgIcons.MetaIcon />;
    case "LinkedinIcon":
      return <SvgIcons.LinkedinIcon />;
    case "TaboolaIcon":
      return <SvgIcons.TaboolaIcon />;
    case "TwitterIcon":
      return <SvgIcons.TwitterIcon />;
    case "TiktokIcon":
      return <SvgIcons.TiktokIcon />;
    case "MegaPhoneIcon":
      return <SvgIcons.MegaPhoneIcon />;
    case "LayoutLeftPanel2Icon":
      return <SvgIcons.LayoutLeftPanel2Icon />;
    case "ControlIcon":
      return <SvgIcons.ControlIcon />;
    case "DifferenceIcon":
      return <SvgIcons.DifferenceIcon />;
    case "SearchIcon":
      return <SvgIcons.SearchIcon />;
    case "DisplayIcon":
      return <SvgIcons.DisplayIcon />;
    case "ShoppingIcon":
      return <SvgIcons.ShoppingIcon />;
    case "Shopping2Icon":
        return <SvgIcons.Shopping2Icon />;
    case "WizardIcon":
      return <SvgIcons.WizardIcon />;
    case "ImageIcon":
      return <SvgIcons.ImageIcon />;
    case "SupportIcon":
      return <SvgIcons.SupportIcon />
    case "MediaIcon":
      return <SvgIcons.MediaIcon />
      case "PantoneIcon":
        return <SvgIcons.PantoneIcon />
    default:
      return;
  }
}

export default function MenuItemText({ item, parentItem }) {

  const { t } = useTranslation();
  const { setSelectedRowData } = useContext(SelectedRowContext);

  const handleGoogleAds = () => {
    const googleAds = ["googleSearch", "googleDisplay", "smartShopping"];
    if (parentItem && googleAds.includes(parentItem.title)) {
      setSelectedRowData({});
    }
  };

  return (
    <>
      {/*item.icon && <i className={`kt-menu__link-icon ${item.icon}`} />*/}
      {item.icon && <span className="kt-menu__link-icon"> {getMenuIcon(item.icon)}</span>}

      {parentItem && parentItem.bullet === "dot" && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--dot">
          <span />
        </i>
      )}

      {parentItem && parentItem.bullet === "line" && (
        <i className="kt-menu__link-bullet kt-menu__link-bullet--line">
          <span />
        </i>
      )}

      <span className="kt-menu__link-text" style={{ whiteSpace: "nowrap" }} onClick={handleGoogleAds}>
        {item.title ? t(`aside.${item.title}`) : ""}
      </span>

      {item.badge && (
        <span className="kt-menu__link-badge">
          <span className={`kt-badge ${item.badge.type}`}>
            {item.badge.value}
          </span>
        </span>
      )}

      {item.submenu && <i className="kt-menu__ver-arrow la la-angle-right" />}
    </>
  );
};
