/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from "react";

export const TabsContext = React.createContext();

const TabsProvider = (props) => {
    const [activeAdsAccounts, setActiveAdsAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState();
    const [active, setActive] = useState();
    const [salesConnectSubscribed, setSalesConnectSubscribed] = useState(false);
    return (
        <TabsContext.Provider
            value={{
                activeAdsAccounts, setActiveAdsAccounts,
                selectedAccount, setSelectedAccount,
                active, setActive,
                salesConnectSubscribed, setSalesConnectSubscribed
            }}
        >
            {props.children}
        </TabsContext.Provider>
    );
};

export default TabsProvider;
