import {
  logout
} from "../../app/store/auth";
import {
  setLoading
} from "../../app/store/data";




export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      config.baseURL = "/api/";
      config.withCredentials = true;
      config.timeout = 0;
      store.dispatch(setLoading(store.getState().data.loading + 1));
      config.headers.locale = store.getState().data.locale;
      return config;
    },
    err => {
      store.dispatch(setLoading(store.getState().data.loading - 1));
      Promise.reject(err);
    }
  );

  axios.interceptors.response.use(function (response) {
    store.dispatch(setLoading(store.getState().data.loading - 1));
    return response;
  }, function (error) {
    store.dispatch(setLoading(store.getState().data.loading - 1));
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
    }
    return Promise.reject(error);

  });
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
      key +
      "] from localStorage: " +
      JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
      key +
      "] in localStorage: " +
      JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export function isAdBlockActive() {
  const className = ['pub_300x250', 'pub_300x250m', 'pub_728x90', 'text-ad', 'textAd', 'text_ad', 'text_ads', 'text-ads', 'adsbox'];
  let blockedElement = document.querySelector('.' + className.join('.'));
  if (!blockedElement) {
    blockedElement = document.createElement('div');
    blockedElement.className = className.join(' ');
    blockedElement.setAttribute('style', 'position: absolute; top: -10px; left: -10px; width: 1px; height: 1px;');
    document.body.appendChild(blockedElement);
  }

  return window.document.body.getAttribute('abp') !== null ||
    blockedElement.offsetParent === null ||
    blockedElement.offsetHeight === 0 ||
    blockedElement.offsetLeft === 0 ||
    blockedElement.offsetTop === 0 ||
    blockedElement.offsetWidth === 0 ||
    blockedElement.clientHeight === 0 ||
    blockedElement.clientWidth === 0
}

export function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function formatNumber(num, locale = "en") {

  const commaSeparator = ["de", "es", "pt", "fr", "it"];

  const value = Number(num);
  return commaSeparator.includes(locale) ? value.toLocaleString('de-DE', {
    minimumFractionDigits: 2
  }) : value.toLocaleString('en-US', {
    minimumFractionDigits: 2
  });
}

export function isValidURL(url) {
  if (!url) return false;
  var res = url.match(/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/);
  return (res !== null)
};

export function isValidText(text) {
  if (!text) return false;
  const response = text.match(/[,!@%^*()={}~`<>?\\|;]/);
  if (response) return false;
  return true;
}

export function isSameDomain(url, domain) {
  if (!url || !domain) return false;
  url = url.trim().replace(/http:\/\/|https:\/\/|www./g, "");
  domain = domain.trim().replace(/http:\/\/|https:\/\/|www./g, "");
  return url.startsWith(domain);
};

// Payment Info Validations taken without any change from previous version

function mod97(string) {
  let checksum = string.slice(0, 2),
    fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
};


export function isValidBic(value) {
  return value && value !== "" ? /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(value.toUpperCase()) : false;
};

export function isValidIBAN(input) {
  if(!input || input === "") return false;
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26
  };
  let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits);
}


export function isValidCardNumber(cardNo) {
  if(!cardNo || cardNo === "") return false;
  let s = 0;
  let doubleDigit = false;
  for (let i = cardNo.length - 1; i >= 0; i--) {
    let digit = +cardNo[i];
    if (doubleDigit) {
      digit *= 2;
      if (digit > 9)
        digit -= 9;
    }
    s += digit;
    doubleDigit = !doubleDigit;
  }
  return s % 10 === 0;
}

const EXTERNAL_URL = "https://adconnector.com"

export function getContactLink(locale = "en") {
  if (locale === "de") return `${EXTERNAL_URL}/de/kontakt/`;
  return `${EXTERNAL_URL}/contact`;
};

export function getTnCLink(locale = "en") {
  if (locale === "de") return `${EXTERNAL_URL}/de/agb/`;
  return `${EXTERNAL_URL}/gtc`;
};

export function getPrivacyLink(locale = "en") {
  if (locale === "de") return `${EXTERNAL_URL}/de/datenschutz/`;
  return `${EXTERNAL_URL}/privacy`;
};

export function getGooglePrivacyLink(locale = "en") {
  if (locale === "de") return "https://policies.google.com/privacy?hl=de";
  return `https://policies.google.com/privacy?hl=en`;
};

export function getGoogleTnCLink(locale = "en") {
  if (locale === "de") return "https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldi=31371";
  return "https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldi=15825";
};

export function createTranslationKey(input) {
  return input.replace(/ /g, "-").toLowerCase();
}