import axios from "axios";

export function generateOAuthUrl() {
    return axios.get("google_ads/auth_url");
}

export function getAccountsList() {
    return axios.get("/users/id/google_ads/accounts");
}

export function getAccountsListByToken(code: string) {
    return axios.post("/users/id/google_ads/token", { code: code });
}

export function updateActiveAccount(customer, client, status, website) {
    return axios.put("/users/id/google_ads/active_account", {
        customer: customer,
        client: client,
        status: status,
        website: website,
    });
}

export function deleteActiveAccount(account) {
    return axios.put("/users/id/google_ads/delete_account", { account: account });
}

export function switchActiveAccount(account) {
    return axios.put("/users/id/google_ads/switch_account", { account: account });
}

export function deleteAccount(client) {
    return axios.delete(`/users/id/google_ads/delete_account/${client}`);
}

export function createNewAccount(input) {
    return axios.post("/users/id/google_ads/account", input);
}

export function deleteManagedAccount() {
    return axios.delete("/users/id/google_ads/account", {});
}

export function removeAccount() {
    return axios.post("/users/id/google_ads/remove_account", {});
}

export function getAllRecommendations(input) {
    return axios.get("/users/id/google_ads/recommendations", { params: input });
}

export function getSearchCampaigns(input) {
    return axios.get("/users/id/google_ads/search/campaigns", { params: input });
}

export function getSingleSearchCampaign(input) {
    return axios.get("/users/google_ads/search/campaign", { params: input });
}

export function createSearchCampaign(campaign) {
    return axios.post("/users/id/google_ads/search/campaigns", campaign);
}

export function updateSearchCampaign(campaigns) {
    return axios.put("/users/id/google_ads/search/campaigns", campaigns);
}

export function deleteSearchCampaigns(resources) {
    return axios.post("/users/id/google_ads/search/campaigns/delete", resources);
}

export function updateSearchCampaignBudget(budget) {
    return axios.put("/users/id/google_ads/search/campaign_budget", budget);
}

export function getSearchAdGroups(input) {
    return axios.get("/users/id/google_ads/search/ad_groups", { params: input });
}

export function getSingleSearchAdGroups(input) {
    return axios.get("/users/google_ads/search/ad_group", { params: input });
}

export function createSearchAdGroup(adGroup) {
    return axios.post("/users/id/google_ads/search/ad_groups", adGroup);
}

export function updateSearchAdGroup(adGroups) {
    return axios.put("/users/id/google_ads/search/ad_groups", adGroups);
}

export function deleteSearchAdGroups(resources) {
    return axios.post("/users/id/google_ads/search/ad_groups/delete", resources);
}

export function getSearchAdGroupAds(input) {
    return axios.get("/users/id/google_ads/search/ad_group_ads", { params: input });
}

export function createSearchAdGroupAd(adgroupAd) {
    return axios.post("/users/id/google_ads/search/ad_group_ads", adgroupAd);
}

export function updateSearchAdGroupAds(adGroupAds) {
    return axios.put("/users/id/google_ads/search/ad_group_ads", adGroupAds);
}

export function updateSearchAdGroupAd(adgroupAd) {
    return axios.put("/users/id/google_ads/search/ad_group_ad", adgroupAd);
}

export function deleteSearchAdGroupAds(resources) {
    return axios.post("/users/id/google_ads/search/ad_group_ads/delete", resources);
}

export function getSearchKeywords(input) {
    return axios.get("/users/id/google_ads/search/keywords", { params: input });
}

export function createSearchKeyword(keyword) {
    return axios.post("/users/id/google_ads/search/keywords", keyword);
}

export function updateSearchKeyword(keywords) {
    return axios.put("/users/id/google_ads/search/keywords", keywords);
}

export function deleteSearchKeywords(resources) {
    return axios.post("/users/id/google_ads/search/keywords/delete", resources);
}

export function getExtensions(input) {
    return axios.get("/users/id/google_ads/extensions", { params: input });
}

export function createExtension(extension) {
    return axios.post("/users/id/google_ads/extensions", extension);
}

export function updateExtension(extension) {
    return axios.put("/users/id/google_ads/extensions", extension);
}

export function deleteExtensions(respources) {
    return axios.post("/users/id/google_ads/extensions/delete", respources);
}

export function getGeoTargetConstantSuggestions(input) {
    return axios.get("/users/id/google_ads/geo_target_constants/suggestion", { params: input });
}

export function getKeywordPlanIdeas(input) {
    return axios.get("/users/id/google_ads/search/keyword_plan_idea", { params: input });
}

export function createAdWizard(input) {
    return axios.post("/users/id/google_ads/ad_wizard", input);
}

export function qualification(input) {
    return axios.post("/users/id/google_ads/qualification", input);
}

export function getQualification() {
    return axios.get("/users/id/google_ads/qualification");
}

export function getCustomerMetrics(input) {
    return axios.get("/users/id/google_ads/customer/metrics/", { params: input });
}

export function getCustomerPhoneMetrics(input) {
    return axios.get("/users/id/google_ads/customer/phone_metrics/", { params: input });
}

export function getChangeStatus(input) {
    return axios.get("/users/id/google_ads/change_status/", { params: input });
}

export function getConversions() {
    return axios.get("/users/id/google_ads/conversions");
}

export function createConversion(input) {
    return axios.post("/users/id/google_ads/conversion/create", input);
}

/* Smart Shopping */

export function getShoppingCampaigns(input) {
    return axios.get("/users/id/google_ads/shopping/campaigns", { params: input });
}

export function getSingleShoppingCampaign(input) {
    return axios.get("/users/google_ads/shopping/campaign", { params: input });
}

export function createShoppingCampaign(campaign) {
    return axios.post("/users/id/google_ads/shopping/campaigns", campaign);
}

export function updateShoppingCampaign(campaigns) {
    return axios.put("/users/id/google_ads/shopping/campaigns", campaigns);
}

export function deleteShoppingCampaigns(resources) {
    return axios.post("/users/id/google_ads/shopping/campaigns/delete", resources);
}

export function updateShoppingCampaignBudget(budget) {
    return axios.put("/users/id/google_ads/shopping/campaign_budget", budget);
}

export function getShoppingAdGroups(input) {
    return axios.get("/users/id/google_ads/shopping/ad_groups", { params: input });
}

export function getSingleShoppingAdGroups(input) {
    return axios.get("/users/google_ads/shopping/ad_group", { params: input });
}

export function createShoppingAdGroup(adGroup) {
    return axios.post("/users/id/google_ads/shopping/ad_groups", adGroup);
}

export function updateShoppingAdGroup(adGroups) {
    return axios.put("/users/id/google_ads/shopping/ad_groups", adGroups);
}

export function deleteShoppingAdGroups(resources) {
    return axios.post("/users/id/google_ads/shopping/ad_groups/delete", resources);
}

export function getShoppingAdGroupAds(input) {
    return axios.get("/users/id/google_ads/shopping/ad_group_ads", { params: input });
}

export function createShoppingAdGroupAd(adgroupAd) {
    return axios.post("/users/id/google_ads/shopping/ad_group_ads", adgroupAd);
}

export function updateShoppingAdGroupAds(adgroupAds) {
    return axios.put("/users/id/google_ads/shopping/ad_group_ads", adgroupAds);
}

export function updateShoppingAdGroupAd(adgroupAd) {
    return axios.put("/users/id/google_ads/shopping/ad_group_ad", adgroupAd);
}

export function deleteShoppingAdGroupAds(resources) {
    return axios.post("/users/id/google_ads/shopping/ad_group_ads/delete", resources);
}

export function getSerchTermsReport(input) {
    return axios.get("/users/id/google_ads/reports/search_terms", { params: input });
}

export function getCampaignsReport(input) {
    return axios.get("/users/id/google_ads/reports/campaigns", { params: input });
}

export function getPerformanceReport(input) {
    return axios.get("/users/id/google_ads/reports/performance", { params: input });
}

export function getDevicesReport(input) {
    return axios.get("/users/id/google_ads/reports/devices", { params: input });
}

export function rawQuery(query: string) {
    return axios.post("/users/id/google_ads/query", { query: query });
}

export function dashboard(queries: Object) {
    return axios.post("/users/id/google_ads/dashboard", { queries: queries });
}

export function getInvoices() {
    return axios.get("/users/id/google_ads/invoices");
}

export function getSerachTerms(input) {
    return axios.get("/users/id/google_ads/search_terms", { params: input });
}

export function createKeyword(input) {
    return axios.post("/users/id/google_ads/search_terms/keywords", input);
}

export function createAdSchedule(input) {
    return axios.post("/users/id/google_ads/schedule", input);
}

export function getCampaignCriterion() {
    return axios.get("/users/id/google_ads/campaign/criterion");
}

export function deleteCampaignCriterion(resources) {
    return axios.post("/users/id/google_ads/campaign/criterion/delete", resources);
}

export function getTimeZones() {
    return axios.get("/google_ads/time_zones");
}

export function getCurrencies() {
    return axios.get("/google_ads/currency_codes");
}

export function addPaymentMethod(input) {
    return axios.post("/users/id/google_ads/account/add_payment_method", input);
}

export function validatePaymentMethod(input) {
    return axios.post("/users/id/google_ads/account/validate_payment_method", input);
}

export function dismissRecommendation() {
    return axios.post("/users/id/google_ads/discardRecommendation");
}

export function postEventAdWizard(input: any) {
    return axios.post("/google_ads/event_ad_wizard", input);
}

export function getAllCampaigns(input: any) {
    return axios.get("/users/id/google_ads/getAllCampaigns", { params: input });
}

export function updatePaymentInfoValidated(input) {
    return axios.put("/googleAds/paymentInfoValidated/update", input);
}

export function updatePaymentInfoProvided(input) {
    return axios.put("/googleAds/paymentInfoProvided/update", input);
}

export function removeGoogleAdsAccount(payload) {
    return axios.post("/google_ads/removeAccount",payload);
}

export function googleAdGroupAdsUpdateAI(payload) {
    return axios.post("/google_ads/search/ad_group_ads/updateAi",payload);
}