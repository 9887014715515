import axios from "axios";

export function createPost(data: any) {
    return axios.post("/post/create", data);
}

export function createPostAds(data: any) {
    return axios.post("/ads/create", data);
}

export function getAccount(platform: string) {
    return axios.get("/post/account", { params: { platform: platform } });
}

export function createTemplate(data: any) {
    return axios.post("/saveTemplate", data);
}

export function getTemplate() {
    return axios.get("/getTemplate");
}

export function updateTemplate(data: any) {
    return axios.put("/updateTemplate", data, { params: data.tid });
}

export function deleteTemplate(tid: any) {
    return axios.delete("/deleteTemplate", { params: { tid: tid } });
}

export function checkConnection() {
    return axios.get("/checkConnection");
}

export function checkCity(body: any) {
    return axios.post("/checkTargetLocation", body);
}

export function activateWizard(body: any) {
    return axios.post("/activateWizard", body);
}

export function getPostWizardPost(platform: string) {
    return axios.get("/postwizard/all_post", { params: { platform: platform } });
}

export function postComment(input: any) {
    return axios.post("/postwizard/post/comment", input);
}

export function postDelete(input: any) {
    return axios.post("/postwizard/post/delete", input, { params: { platform: input.platform } });
}

export function postUpdate(input: any) {
    return axios.post("/postwizard/post/update", input, { params: { platform: input.platform } });
}

export function getPostComments(input: any) {
    return axios.post("/postwizard/post/get_comments", input, { params: { platform: input.platform } });
}

export function getStory(platform: string) {
    return axios.get("/postwizard/post/story", { params: { platform: platform } });
}