/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { createContext, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { Store } from "redux";
import { Persistor } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import LoginAsProvider from "./app/context/LoginContext";
import TabsProvider from "./app/context/TabContext";
import { DateRangeContext } from "./app/partials/layout/CustomDateRangePicker";
import { Routes } from "./app/router/Routes";
import { LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { format } from "date-fns";
import SelectedRowProvider from "./app/context/SelectedRowContext";

export type AppProps = {
    store: Store;
    persistor: Persistor;
    basename: string;
};
export const AppContext = createContext<any>({});

const App: React.FunctionComponent<AppProps> = function ({ store, persistor, basename }) {
    const [active, setActive] = useState(false);
    const [stopUserProfileFetchInterval, setStopUserProfileFetchInterval] = useState(false);
    const [callBack, setCallBack] = useState(null);
    const [dateRange, setDateRange] = useState<any>({
        from_date: format(new Date(), "yyyy-MM-dd"),
        to_date: format(new Date(), "yyyy-MM-dd"),
    });

    const DateRangeProps = { active, setActive, callBack, setCallBack, dateRange, setDateRange };

    return (
        /* Provide Redux store */
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen />}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <AppContext.Provider
                        value={{
                            stopUserProfileFetchInterval,
                            setStopUserProfileFetchInterval,
                        }}
                    >
                        <BrowserRouter basename={basename}>
                            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                            <LastLocationProvider>
                                {/* Provide Metronic theme overrides. */}
                                <ThemeProvider>
                                    <LoginAsProvider>
                                        {/* Render routes with provided `Layout`. */}
                                        <TabsProvider>
                                            <SelectedRowProvider>
                                                <DateRangeContext.Provider value={DateRangeProps}>
                                                    <Routes />
                                                </DateRangeContext.Provider>
                                            </SelectedRowProvider>
                                        </TabsProvider>
                                    </LoginAsProvider>
                                </ThemeProvider>
                            </LastLocationProvider>
                        </BrowserRouter>
                    </AppContext.Provider>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
};

export default App;
